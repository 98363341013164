/* Footer.module.css */
.footer {
  background-color: #f8fafc;
  color: #2c3e50;
  padding: 1.5rem 0 1rem;
  border-top: 1px solid #e2e8f0;
  font-size: 0.875rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.companyInfo {
  flex: 0 0 100%;
  max-width: 300px;
  margin-bottom: 1rem;
}

.logo {
  font-size: 1.25rem;
  font-weight: bold;
  color: #003366;
  margin-bottom: 0.5rem;
}

.description {
  font-size: 0.8rem;
  line-height: 1.4;
  color: #4a5568;
}

.linksSection {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.linkColumn {
  flex: 1 1 120px;
}

.columnTitle {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #003366;
}

.linkList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.linkList li {
  margin-bottom: 0.25rem;
}

.linkList a {
  color: #4a5568;
  text-decoration: none;
  transition: color 0.2s;
  font-size: 0.8rem;
}

.linkList a:hover {
  color: #4a90e2;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.socialLinks {
  display: flex;
  gap: 0.75rem;
}

.socialLinks a {
  color: #4a5568;
  font-size: 1rem;
  transition: color 0.2s;
}

.socialLinks a:hover {
  color: #4a90e2;
}

.copyright {
  font-size: 0.75rem;
  color: #718096;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .companyInfo {
    max-width: 100%;
  }

  .linksSection {
    width: 100%;
  }

  .bottom {
    flex-direction: column-reverse;
    gap: 0.75rem;
  }
}
