.priceSlider {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.5rem;
}

.slider {
  width: 100%;
}
