.sliderContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px; /* Track height */
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px; /* Thumb height */
  border-radius: 50%;
  background: #2563eb;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
  margin-top: -6px; /* Center the thumb vertically */
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px; /* Thumb height */
  border: 0;
  border-radius: 50%;
  background: #0d9488;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
  margin-top: -6px; /* Center the thumb vertically */
}

.slider::-webkit-slider-thumb:hover {
  background: #2563eb;
}

.slider::-moz-range-thumb:hover {
  background: #2563eb;
}

.slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px; /* Track height */
  cursor: pointer;
  background: linear-gradient(
    to right,
    #052abb var(--background-size, 0%),
    #d7dcdf var(--background-size, 0%)
  );
  border-radius: 5px;
}

.slider::-moz-range-track {
  width: 100%;
  height: 8px; /* Track height */
  cursor: pointer;
  background: #d7dcdf;
  border-radius: 5px;
}

.slider::-moz-range-progress {
  background-color: #0d9488;
  height: 8px; /* Track height */
  border-radius: 5px;
}
