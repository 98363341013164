.select {
  position: relative;
  width: 100%;
}

.selectTrigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #374151;
  cursor: pointer;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.selectTrigger:hover {
  border-color: #9ca3af;
}

.selectTrigger:focus {
  outline: none;
  border-color: #0d9488;
  box-shadow: 0 0 0 3px rgba(13, 148, 136, 0.1);
}

.selectTrigger.open {
  border-color: #0d9488;
}

.selectTrigger:disabled {
  background-color: #f3f4f6;
  color: #9ca3af;
  cursor: not-allowed;
}

.selectValue {
  flex-grow: 1;
}

.icon {
  width: 1rem;
  height: 1rem;
  transition: transform 0.2s;
}

.open .icon {
  transform: rotate(180deg);
}

.selectContent {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  margin-top: 0.25rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  max-height: 15rem;
  overflow-y: auto;
}

.selectItem {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.selectItem:hover {
  background-color: #f3f4f6;
}
