/* Header.module.css */
.header {
  background-color: #003366;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f5f7fa;
  text-decoration: none;
}

.nav {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.navList {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navLink {
  color: #f5f7fa;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  transition: color 0.2s;
}

.navLink:hover {
  color: #ff9900;
}

.active {
  color: #ff9900;
}

.icon {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    width: 100%;
    margin-top: 1rem;
  }

  .navList {
    flex-direction: column;
    width: 100%;
  }

  .navLink {
    padding: 0.5rem 0;
  }
}
