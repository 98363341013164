.dropdown {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.5rem;
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #374151;
  cursor: pointer;
  transition: border-color 0.2s;
}

.trigger:hover {
  border-color: #9ca3af;
}

.trigger:focus {
  outline: none;
  border-color: #0d9488;
  box-shadow: 0 0 0 3px rgba(13, 148, 136, 0.1);
}

.option {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}
