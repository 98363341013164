/* BookingOffers.module.css */
.page {
  min-height: 100vh;
  background-color: #f3f4f6;
}

.main {
  padding: 2rem 0;
}

.container {
  max-width: 64rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.resultsColumn {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.loading,
.error,
.noResults {
  text-align: center;
  font-size: 1.125rem;
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error {
  color: #ef4444;
}
