.card {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
}

.cardHeader {
  padding: 1.5rem;
  background-color: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
  background-image: linear-gradient(to right, #f0f9ff 2px, transparent 2px),
    linear-gradient(to bottom, #f0f9ff 2px, transparent 2px);
  background-size: 20px 20px;
}

.tripInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tripSummary {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: #1e293b;
  margin: 0;
}

.checkOffersButton {
  font-size: 0.875rem;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkOffersButton:hover {
  background-color: #2563eb;
  transform: translateY(-1px);
}

.tripActions {
  display: flex;
  align-items: center;
}

.tripCost {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  background-color: #e2e8f0;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
}

.costIcon {
  margin-right: 0.25rem;
  color: #64748b;
}

.costValue {
  font-weight: bold;
  color: #1e293b;
}

.favoriteButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.favoriteButton:hover {
  background-color: #e2e8f0;
  transform: scale(1.1);
}

.favoriteIcon {
  color: #64748b;
  width: 1.5rem;
  height: 1.5rem;
  transition: color 0.3s ease;
}

.favoriteActive .favoriteIcon {
  color: #ef4444;
}

.cardBody {
  padding: 1.5rem;
}

.flightInfo,
.hotelInfo {
  margin-bottom: 2rem;
}

.sectionTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  color: #1e293b;
}

.titleIcon {
  margin-right: 0.5rem;
  color: #3b82f6;
}

.flightOptions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flightOption {
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.flightOption:hover,
.selectedFlight {
  border-color: #3b82f6;
  background-color: #f0f9ff;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.1);
}

.flightMainInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.flightRoute {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flightLegs {
  display: flex;
  gap: 1rem;
}

.flightLeg {
  flex: 1;
}

.legInfoTimeline {
  display: flex;
  align-items: center;
  width: 100%;
}

.flightDetails {
  display: flex;
  align-items: center;
  width: 100%;
}

.flightIcons {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.planeIcon {
  color: #3b82f6;
  width: 1.5rem;
  height: 1.5rem;
}

.returnIcon {
  transform: rotate(180deg);
}

.flightInfo {
  flex-grow: 1;
}

.flightMainDetails {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.flightTime {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1e293b;
}

.flightIataCode {
  font-size: 1rem;
  color: #64748b;
}

.flightRouteVisual {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0 0.5rem;
}

.flightRouteLine {
  flex-grow: 1;
  height: 2px;
  background-image: linear-gradient(to right, #3b82f6 50%, transparent 50%);
  background-size: 8px 2px;
  background-repeat: repeat-x;
}

.arrowIcon {
  color: #3b82f6;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

.flightSecondaryDetails {
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  color: #64748b;
}

.flightDate,
.flightDuration,
.flightType {
  display: flex;
  align-items: center;
}

.flightPrice {
  font-weight: bold;
  color: #1e293b;
  font-size: 1.25rem;
}

.flightAdditionalInfo {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  color: #64748b;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e2e8f0;
}

.flightLabel {
  font-weight: 600;
  color: #4b5563;
  margin-right: 0.25rem;
}

.flightClass,
.flightSeat,
.flightBaggage,
.flightDuration {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.baggageIcon,
.durationIcon {
  width: 1rem;
  height: 1rem;
  color: #64748b;
}

.hotelOptions {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.hotelOption {
  padding: 0.5rem 1rem;
  background-color: #f1f5f9;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  color: #64748b;
}

.hotelOption:hover,
.selectedHotel {
  background-color: #3b82f6;
  color: white;
  transform: translateY(-1px);
}

.selectedHotelInfo {
  background-color: #f8fafc;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.hotelName {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #1e293b;
}

.hotelRating {
  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.starIcon {
  color: #f59e0b;
}

.hotelPrice {
  font-weight: bold;
  color: #1e293b;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f5f9;
  padding: 0.75rem;
  border-radius: 0.5rem;
}

.hotelPrice > div:first-child {
  font-size: 0.875rem;
  color: #64748b;
}

.hotelPrice > div:last-child {
  font-size: 1.125rem;
}

.totalDays {
  font-size: 0.875rem;
  font-weight: 600;
  color: #3b82f6;
  background-color: #e0f2fe;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hotelDates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  background-color: #f1f5f9;
  border-radius: 0.5rem;
  padding: 1rem;
}

.checkInDate,
.checkOutDate {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dateIcon {
  color: #3b82f6;
  width: 1.5rem;
  height: 1.5rem;
}

.dateInfo {
  display: flex;
  flex-direction: column;
}

.dateLabel {
  font-size: 0.75rem;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.date {
  font-size: 0.875rem;
  font-weight: 600;
  color: #1e293b;
}

.cardFooter {
  padding: 1.5rem;
  border-top: 1px solid #e2e8f0;
}

.bookButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bookButton:hover {
  background-color: #2563eb;
  transform: translateY(-1px);
}

.buttonIcon {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .tripInfo {
    flex-direction: column;
    align-items: flex-start;
  }

  .tripSummary {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .tripActions {
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .checkOffersButton {
    width: 100%;
    margin-top: 0.5rem;
  }

  .favoriteButton {
    margin-right: 0.5rem;
  }

  .tripCost {
    margin-left: 0;
  }

  .flightAdditionalInfo {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .hotelOptions {
    flex-wrap: wrap;
  }

  .flightOption {
    padding: 0.75rem;
  }

  .flightMainInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .flightPrice {
    align-self: flex-end;
  }

  .flightLegs {
    flex-direction: column;
  }

  .flightDetails {
    flex-direction: column;
    align-items: flex-start;
  }

  .flightIcons {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .flightMainDetails,
  .flightSecondaryDetails {
    flex-wrap: wrap;
  }

  .hotelDates {
    flex-direction: column;
    gap: 0.5rem;
  }
}
