.button {
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1rem;
  text-align: center;
  display: inline-block;
}

.button:hover {
  background-color: #2563eb;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.button:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
}
