.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #e6f2ff 0%, #ffffff 100%);
}

.main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.container {
  background-color: white;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 64rem;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  color: #095390;
  letter-spacing: -0.025em;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .formGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.searchButton:active {
  transform: translateY(0);
}

.bookingsContainer {
  margin-top: 3rem;
}

.bookingsTitle {
  font-size: 1.875rem;
  font-weight: 600;
  color: #0d44ba;
  margin-bottom: 1.5rem;
  text-align: center;
}
