/* components/UI/carousel/Carousel.module.css */
.carousel {
  position: relative;
  width: 100%;
  height: 24rem;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.slideContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.activeSlide {
  z-index: 1;
}

.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;
  border: none;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  z-index: 2;
}

.navButton:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.prevButton {
  left: 1rem;
}

.nextButton {
  right: 1rem;
}

.dotsContainer {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
  z-index: 2;
}

.dot {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot:hover,
.activeDot {
  background-color: white;
}

.thumbnails {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
  z-index: 2;
}

.thumbnail {
  width: 8rem;
  height: 4rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s;
  padding: 0;
  background: none;
}

.thumbnail:hover,
.activeThumbnail {
  border-color: white;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 640px) {
  .carousel {
    height: 18rem;
  }

  .thumbnails {
    display: none;
  }

  .navButton {
    width: 2rem;
    height: 2rem;
  }
}
