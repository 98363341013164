.signupContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #0d9488; /* Using the same green as header and footer */
  padding: 2rem;
}

.signupCard {
  width: 100%;
  max-width: 450px;
  display: flex;
  justify-content: center;
}

.clerkRoot {
  width: 100%;
}

.clerkCard {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.headerTitle {
  font-size: 2rem;
  color: #0d9488;
  text-align: center;
  margin-bottom: 1rem;
}

.headerSubtitle {
  font-size: 1rem;
  color: #555;
  text-align: center;
  margin-bottom: 1.5rem;
}

.input {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.input:focus {
  outline: none;
  border-color: #0d9488;
  box-shadow: 0 0 0 3px rgba(13, 148, 136, 0.2);
}

.label {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.submitButton {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background-color: #0d9488;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submitButton:hover {
  background-color: #0f766e;
}

.socialButton {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.75rem;
}

.socialButton:hover {
  background-color: #eeeeee;
  border-color: #bdbdbd;
}

.socialButtonArrow {
  display: none;
}

.socialButtonText {
  flex-grow: 1;
  text-align: center;
}

.dividerLine {
  border-top: 1px solid #e0e0e0;
  margin: 1.5rem 0;
}

.dividerText {
  font-size: 0.9rem;
  color: #757575;
  text-align: center;
  margin-top: -1.75rem;
  background-color: white;
  padding: 0 0.5rem;
  display: inline-block;
}

.formFieldAction {
  font-size: 0.9rem;
  color: #0d9488;
  text-decoration: none;
  transition: color 0.2s ease;
}

.formFieldAction:hover {
  color: #0f766e;
}

.footerActionLink {
  font-size: 0.9rem;
  color: #0d9488;
  text-decoration: none;
  transition: color 0.2s ease;
}

.footerActionLink:hover {
  color: #0f766e;
}

.redirectLink {
  margin-top: 1.5rem;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  transition: color 0.2s ease;
}

.redirectLink:hover {
  color: #e6fffa;
  text-decoration: underline;
}
